/* eslint-disable */
// https://dev2.bhsn.it/api/legalc/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '$lib/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'aireview';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);

    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}
/**
 * @returns string OK
 * @throws ApiError
 */
export function hello(requestConfig: RequestConfig = {}): AxiosPromise<string> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/hello',
            }),
            requestConfig,
        ),
    );
}
/**
 * SSO
 * @returns LegalcRedirectView OK
 * @throws ApiError
 */
export function sso(
    {
        requestBody,
    }: {
        requestBody: LegalcSsoForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<LegalcRedirectView> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/sso.fo',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 로그인
 * @returns LegalcCjcjResponseObject OK
 * @throws ApiError
 */
export function login(
    {
        requestBody,
    }: {
        requestBody: LegalcLoginForm;
    },
    requestConfig: RequestConfig = {},
): AxiosPromise<LegalcCjcjResponseObject> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/login/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
