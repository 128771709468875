/* eslint-disable */
// https://dev2.bhsn.it/api/heimdall/swagger-ui/index.html
import type { AxiosPromise, AxiosInstance } from 'axios';
import { toAxiosOptions, mergeObjects } from '@allibee/utils';

let axiosInstance: AxiosInstance;
let baseURL: string;

const serviceRoute = 'heimdall';
export function setupServiceInstance(callback: (serviceRoute: string) => { baseURL: string; instance: AxiosInstance }) {
    const result = callback(serviceRoute);
    axiosInstance = result.instance;
    baseURL = result.baseURL;
}

export function getBaseURL() {
    return baseURL;
}

/**
 * API Key 검증
 * @returns HeimdallResponseApiKeyDto OK
 * @throws ApiError
 */
export function verifyApiKey(
    {
        requestBody,
    }: {
        requestBody: HeimdallApiKeyVerifyForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponseApiKeyDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/api-keys/verify/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 자격증명 생성 (벌크)
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function createBulkUserCredentials(
    {
        requestBody,
    }: {
        requestBody: HeimdallBulkUserCredentialCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/external/user-credentials/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 자격증명 수정 (재설정 토큰)
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function resetUserCredential(
    {
        requestBody,
    }: {
        requestBody: HeimdallUserCredentialResetForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/user-credentials/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 자격증명 생성
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function createUserCredential(
    {
        requestBody,
    }: {
        requestBody: HeimdallUserCredentialCreateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/user-credentials/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 자격증명 제거
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function deleteUserCredential(
    {
        requestBody,
    }: {
        requestBody: HeimdallUserCredentialDeleteForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'DELETE',
                url: '/user-credentials/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 자격증명 수정 (로그인 상태)
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function updateUserCredential(
    {
        requestBody,
    }: {
        requestBody: HeimdallUserCredentialUpdateForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PATCH',
                url: '/user-credentials/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 사용자 자격증명 강제 수정 (슈퍼어드민 전용)
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function forceResetUserCredential(
    {
        requestBody,
    }: {
        requestBody: HeimdallUserCredentialForceResetForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'PUT',
                url: '/user-credentials/force/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 서비스 토큰 조회
 * @returns HeimdallResponseServiceToken OK
 * @throws ApiError
 */
export function issueToken(
    {
        requestBody,
    }: {
        requestBody: HeimdallServiceAuthForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponseServiceToken> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/service-auth/tokens/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 재발급 V2
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function refreshV2(_?: any, requestConfig?: RequestConfig): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/refresh/v2',
            }),
            requestConfig,
        ),
    );
}
/**
 * 재발급
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function refresh(
    {
        lissRft,
    }: {
        lissRft: string;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/refresh/v1',
                cookies: {
                    liss_rft: lissRft,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 비밀번호 초기화
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function resetCredential(
    {
        requestBody,
    }: {
        requestBody: HeimdallRedirectMailForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/message/reset-credentials/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 로그아웃
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function logout(_?: any, requestConfig?: RequestConfig): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/logout/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 2FA 인증 로그인 V1
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function verificationLogin(
    {
        xVerificationToken,
        requestBody,
    }: {
        xVerificationToken: string;
        requestBody: HeimdallUserAuthenticationForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/login/verification/v1',
                headers: {
                    'X-Verification-Token': xVerificationToken,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 로그인 V2
 * @returns HeimdallResponseUserAuthenticationDto OK
 * @throws ApiError
 */
export function loginV2(
    {
        requestBody,
    }: {
        requestBody: HeimdallLoginForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponseUserAuthenticationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/login/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 로그인
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function login(
    {
        requestBody,
    }: {
        requestBody: HeimdallLoginForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/login/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼 어드민 전용 2FA 인증 로그인 V1
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function verificationLoginForSuperAdmin(
    {
        xVerificationToken,
        requestBody,
    }: {
        xVerificationToken: string;
        requestBody: HeimdallUserAuthenticationForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/login/super-admin/verification/v1',
                headers: {
                    'X-Verification-Token': xVerificationToken,
                },
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 슈퍼 어드민 전용 로그인
 * @returns HeimdallResponseUserAuthenticationDto OK
 * @throws ApiError
 */
export function loginForSuperAdminV2(
    {
        requestBody,
    }: {
        requestBody: HeimdallLoginForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponseUserAuthenticationDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/login/super-admin/v2',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 슈퍼 어드민 전용 로그인
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function loginForSuperAdmin(
    {
        requestBody,
    }: {
        requestBody: HeimdallLoginForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/login/super-admin/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * Impersonated 로그인(사용자 대리 로그인)
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function impersonatedLogin(
    {
        requestBody,
    }: {
        requestBody: HeimdallImpersonatedLoginForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/login/impersonated/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 로그인 (개발용 non-cookie 버전)
 * @returns HeimdallResponseTokenPair OK
 * @throws ApiError
 */
export function loginForDev(
    {
        requestBody,
    }: {
        requestBody: HeimdallLoginForm;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponseTokenPair> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'POST',
                url: '/login/dev/v1',
                body: requestBody,
                mediaType: 'application/json',
            }),
            requestConfig,
        ),
    );
}
/**
 * 유저 로그인 추적 정보 조회
 * @returns HeimdallResponseListUserLoginTrackDto OK
 * @throws ApiError
 */
export function listUserLoginTracks(
    {
        userIds,
    }: {
        userIds?: Array<number>;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponseListUserLoginTrackDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/user-login-tracks/v1',
                query: {
                    userIds: userIds,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * 토큰 유효성 검사
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function verifyToken(_?: any, requestConfig?: RequestConfig): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/token/verify/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 리프레시 가능 시각 조회
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function getRefreshAfter(_?: any, requestConfig?: RequestConfig): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/refresh-after/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 로그인 여부 확인
 * @returns HeimdallResponse OK
 * @throws ApiError
 */
export function checkLoggedIn(_?: any, requestConfig?: RequestConfig): AxiosPromise<HeimdallResponse> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/check-logged-in/v1',
            }),
            requestConfig,
        ),
    );
}
/**
 * 테넌트/워크스페이스 별 사용자 권한 목록 조회 V3
 * @returns HeimdallResponseListPermissionDto OK
 * @throws ApiError
 */
export function listWorkspaceUserPermissionsV3(_?: any, requestConfig?: RequestConfig): AxiosPromise<HeimdallResponseListPermissionDto> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/permissions/v3',
            }),
            requestConfig,
        ),
    );
}
/**
 * 테넌트/워크스페이스 별 사용자 권한 목록 조회 V2
 * @returns HeimdallResponseListString OK
 * @throws ApiError
 */
export function listWorkspaceUserPermissions(
    {
        tenantId,
        workspaceId,
    }: {
        tenantId: number;
        workspaceId: number;
    } = {} as any,
    requestConfig?: RequestConfig,
): AxiosPromise<HeimdallResponseListString> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/permissions/v2',
                query: {
                    tenantId: tenantId,
                    workspaceId: workspaceId,
                },
            }),
            requestConfig,
        ),
    );
}
/**
 * @deprecated
 * 사용자 권한 목록 조회
 * @returns HeimdallResponseListString OK
 * @throws ApiError
 */
export function listPermissions(_?: any, requestConfig?: RequestConfig): AxiosPromise<HeimdallResponseListString> {
    return axiosInstance(
        mergeObjects(
            toAxiosOptions({
                method: 'GET',
                url: '/permissions/v1',
            }),
            requestConfig,
        ),
    );
}
